import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
//import PrivateRoute from './privateRoute';
// import DoctorHomepage from './components/pages/doctor/Homepage';
// import Receptionisthomepage from './components/pages/human-resource/Receptionisthomepage';
// import Pharmacisthomepage from './components/pages/human-resource/Pharmacisthomepage';
// import Adminhomepage from './components/pages/Adminhomepage';
import { Toaster } from 'react-hot-toast';
import PrivateRoute from "./privateRoute";
import NotFound from "./components/util/NotFound";

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));
// Pages
const Animation = React.lazy(() => import("./components/pages/Animation"));
const Widgets = React.lazy(() => import("./components/pages/Widgets"));
// appointment
const Addappointment = React.lazy(() => import("./components/pages/appointment/Addappointment"));
const Appointmentlist = React.lazy(() => import("./components/pages/appointment/Appointmentlist"));

//appointment with search and add patient profile
const AddSearchPatientAppointment = React.lazy(() => import("./components/pages/add-search-patient-appointment/AddSearchPatientAppointment"));
const AddSearchPatientAppointmentReceptionist = React.lazy(() => import("./components/pages/add-search-patient-appointment/AddSearchPatientAppointmentReceptionist"));

//Appointment pages For Receptionist
const AddappointmentReceptionist = React.lazy(() => import("./components/pages/appointment/AddappointmentReceptionist"));
const AppointmentlistReceptionist = React.lazy(() => import("./components/pages/appointment/AppointmentlistReceptionist"));

//Appointment list for Doctor
const AppointmentlistDoctor = React.lazy(() => import("./components/pages/appointment/AppointmentlistDoctor"));

//Appointment list for Patient
const AppointmentlistPatient = React.lazy(() => import("./components/pages/appointment/AppointmentlistPatient"));

//Appointment Vital Signs
const AddAppointmentVitalSigns = React.lazy(() => import("./components/pages/appointment-vital-signs/AddAppointmentVitalSigns"));
const EditAppointmentVitalSigns = React.lazy(() => import("./components/pages/appointment-vital-signs/EditAppointmentVitalSigns"));

//Appointment Vital Signs for Doctor
const EditAppointmentVitalSignsDoctor = React.lazy(() => import("./components/pages/appointment-vital-signs/EditAppointmentVitalSignsDoctor"));

// apps
const Chat = React.lazy(() => import("./components/pages/apps/Chat"));
const Email = React.lazy(() => import("./components/pages/apps/Email"));
const Todolist = React.lazy(() => import("./components/pages/apps/Todolist"));

//room manager
const AddRoomCategory = React.lazy(() => import("./components/pages/room-manager/AddRoomCategory"));
const RoomCategoryList = React.lazy(() => import("./components/pages/room-manager/RoomCategoryList"));

const AddRoom = React.lazy(() => import("./components/pages/room-manager/AddRoom"));
const RoomList = React.lazy(() => import("./components/pages/room-manager/RoomList"));

// bed manager
const Addbedcategory = React.lazy(() => import("./components/pages/bed-manager/Addbedcategory"));
const Bedcategorylist = React.lazy(() => import("./components/pages/bed-manager/Bedcategorylist"));

const Addbed = React.lazy(() => import("./components/pages/bed-manager/Addbed"));
const Bedlist = React.lazy(() => import("./components/pages/bed-manager/Bedlist"));

// Bed list page for Receptionist
const BedlistReceptionist = React.lazy(() => import("./components/pages/bed-manager/BedlistReceptionist"));
// charts
const Chartjs = React.lazy(() => import("./components/pages/charts/Chartjs"));
const Googlecharts = React.lazy(() => import("./components/pages/charts/Googlecharts"));
// dashboard
const Clientmanagement = React.lazy(() => import("./components/pages/dashboard/Clientmanagement"));
const Projectmanagement = React.lazy(() => import("./components/pages/dashboard/Projectmanagement"));
const Socialmedia = React.lazy(() => import("./components/pages/dashboard/Socialmedia"));
const Webanalytics = React.lazy(() => import("./components/pages/dashboard/Webanalytics"));
// department
const Adddepartment = React.lazy(() => import("./components/pages/department/Adddepartment"));
const Departmentlist = React.lazy(() => import("./components/pages/department/Departmentlist"));
// department list page for receptionist
const DepartmentlistReceptionist = React.lazy(() => import(("./components/pages/department/DepartmentlistReceptionist")));

//patient
const PatientHomepage = React.lazy(() => import("./components/pages/patient/Homepage"));

// doctor
const Adddoctor = React.lazy(() => import("./components/pages/doctor/Adddoctor"));
const Doctorlist = React.lazy(() => import("./components/pages/doctor/Doctorlist"));
const DoctorHomepage = React.lazy(() => import("./components/pages/doctor/Homepage"));
// doctor list page for receptionist
const DoctorlistReceptionist = React.lazy(() => import("./components/pages/doctor/DoctolistReceptionist"));
// doctor schedule
const Addschedule = React.lazy(() => import("./components/pages/doctor-schedule/Addschedule"));
const Schedulelist = React.lazy(() => import("./components/pages/doctor-schedule/Schedulelist"));
// doctor Schedule page for Receptionist
const SchedulelistReceptionist = React.lazy(() => import("./components/pages/doctor-schedule/SchedulelistReceptionist"));
// Consultation for admin
const Addconsultation = React.lazy(() => import("./components/pages/consultation/Addconsultation"));
const Consultationlist = React.lazy(() => import("./components/pages/consultation/Consultationlist"));
const Consultationdetails = React.lazy(() => import("./components/pages/consultation/Consultationdetails"));
const Editconsultation = React.lazy(() => import("./components/pages/consultation/Editconsultation"));
const Deleteconsultation = React.lazy(() => import("./components/pages/consultation/Deleteconsultation"));

const PatientconsultationList = React.lazy(() => import("./components/pages/consultation/PatientconsultationList"));

// Consultation for doctor
const AddconsultationDoctor = React.lazy(() => import("./components/pages/consultation/AddconsultationDoctor"));
const ConsultationlistDoctor = React.lazy(() => import("./components/pages/consultation/ConsultationslistDoctor"));
const ConsultationdetailsDoctor = React.lazy(() => import("./components/pages/consultation/ConsultationdetailsDoctor"));
const EditconsultationDoctor = React.lazy(() => import("./components/pages/consultation/EditconsultationDoctor"));
const DeleteconsultationDoctor = React.lazy(() => import("./components/pages/consultation/DeleteconsultationDoctor"));

const FullConsultation = React.lazy(() => import("./components/pages/full-consultation/FullConsultation"));
const FullConsultationDoctor = React.lazy(() => import("./components/pages/full-consultation/FullConsultationDoctor"));

// Consultation for patient
const ConsultationlistPatient = React.lazy(() => import("./components/pages/consultation/ConsultationlistPatient"));
const ConsultationdetailsPatient = React.lazy(() => import("./components/pages/consultation/ConsultationdetailsPatient"));

//Precription for Admin
const Addprescription = React.lazy(() => import("./components/pages/prescription/Addprescription"));
const Prescriptionlist = React.lazy(() => import("./components/pages/prescription/Prescriptionlist"));
const Prescriptiondetails = React.lazy(() => import("./components/pages/prescription/Prescriptiondetails"));
const Editprescription = React.lazy(() => import("./components/pages/prescription/Editprescription"));
const Deleteprescription = React.lazy(() => import("./components/pages/prescription/Deleteprescription"));

//Precription for Doctor
const AddprescriptionDoctor = React.lazy(() => import("./components/pages/prescription/AddprescriptionDoctor"));
const PrescriptiondetailsDoctor = React.lazy(() => import("./components/pages/prescription/PrescriptiondetailsDoctor"));
const EditprescriptionDoctor = React.lazy(() => import("./components/pages/prescription/EditprescriptionDoctor"));
const DeleteprescriptionDoctor = React.lazy(() => import("./components/pages/prescription/DeleteprescriptionDoctor"));

//Prescription list for patient/pharmacist/pathologist
const PrescriptionlistPatient = React.lazy(() => import("./components/pages/prescription/PrescriptionlistPatient"));
const PrescriptiondetailsPatient = React.lazy(() => import("./components/pages/prescription/PrescriptiondetailsPatient"));

const AdmittedPatientPrescriptionListPharmacist = React.lazy(() => import("./components/pages/admitted-patient-prescription-list/AdmittedPatientPrescriptionListPharmacist"));
const AdmittedPatientPrescriptionListPathologist = React.lazy(() => import("./components/pages/admitted-patient-prescription-list/AdmittedPatientPrescriptionListPathologist"));
const PrescriptiondetailsPharmacist = React.lazy(() => import("./components/pages/prescription/PrescriptiondetailsPharmacist"));
const PrescriptionlistPharmacist = React.lazy(() => import("./components/pages/prescription/PrecriptionlistPharmacist"));
const PrescriptionlistPathologist = React.lazy(() => import("./components/pages/prescriptions-test/PrescriptionlistPathologist"));

const FullPrescriptionReceptionist = React.lazy(() => import("./components/pages/full-prescription/FullPrescriptionReceptionist"));
const PrescriptionListReceptionist = React.lazy(() => import("./components/pages/prescription/PrescriptionlistReceptionist"));
const PrescriptionDetailsReceptionist = React.lazy(() => import("./components/pages/prescription/PrescriptionDetailsReceptionist"));

//Prescription test for admin
const Addtest = React.lazy(() => import("./components/pages/prescriptions-test/Addtest"));
const Prescriptiontestdetails = React.lazy(() => import("./components/pages/prescriptions-test/Prescriptiontestdetails"));
const Edittest = React.lazy(() => import("./components/pages/prescriptions-test/Edittest"));
const Deletetest = React.lazy(() => import("./components/pages/prescriptions-test/Deletetest"));

//Prescription test for Doctor
const AddtestDoctor = React.lazy(() => import("./components/pages/prescriptions-test/AddtestDoctor"));
const PrescriptiontestdetailsDoctor = React.lazy(() => import("./components/pages/prescriptions-test/PrescriptiontestdetailsDoctor"));
const EdittestDoctor = React.lazy(() => import("./components/pages/prescriptions-test/EdittestDoctor"));
const DeletetestDoctor = React.lazy(() => import("./components/pages/prescriptions-test/DeletetestDoctor"));

//Prescription test for patient
const PrescriptiontestdetailsPatient = React.lazy(() => import("./components/pages/prescriptions-test/PrescriptiontestdetailsPatient"));

//Prescription test list for pathologist, Receptionist
const EditTestPathologist = React.lazy(() => import("./components/pages/prescriptions-test/EditTestPathologist"));
const PrescriptionTestListPathologist = React.lazy(() => import("./components/pages/prescriptions-test/PrescriptionTestListPathologist"));

const EditTestReceptionist = React.lazy(() => import("./components/pages/prescriptions-test/EditTestReceptionist"));

const PrescriptiontestdetailsPathologist = React.lazy(() => import("./components/pages/prescriptions-test/PrescriptiontestdetailsPathologist"));
const PrescriptionTestInvoiceGenerateReceptionist = React.lazy(() => import("./components/pages/prescriptions-test/PrescriptionTestInvoiceGenerateReceptionist"));

const PrescriptionTestDetailsReceptionist = React.lazy(() => import("./components/pages/prescriptions-test/PrescriptiontestdetailsReceptionist"));

//Followup for admin
const Followuplist = React.lazy(() => import("./components/pages/followup/Followuplist"));
const Addfollowup = React.lazy(() => import("./components/pages/followup/Addfollowup"));

const FullFollowup = React.lazy(() => import("./components/pages/full-followup/FullFollowup"));

//Followup for doctor
const AddfollowupDoctor = React.lazy(() => import("./components/pages/followup/AddfollowupDoctor"));
const FollowuplistDoctor = React.lazy(() => import("./components/pages/followup/FollowuplistDoctor"));

const FullFollowupDoctor = React.lazy(() => import("./components/pages/full-followup/FullFollowupDoctor"));

//Nurse
const NurseHomepage = React.lazy(() => import("./components/pages/nurse/Homepage"));

// available drugs/accessories/tests/services

//Admin
const AddDrugs = React.lazy(() => import("./components/pages/available-drugs/AddDrugs"));
const AddAccessories = React.lazy(() => import("./components/pages/available-accessories/AddAccessories"));
const AddTests = React.lazy(() => import("./components/pages/available-tests/AddTests"));
const AddProcedures = React.lazy(() => import("./components/pages/available-procedures/AddProcedures"));
const AddServices = React.lazy(() => import("./components/pages/available-services/AddServices"));

const Availabledrugs = React.lazy(() => import("./components/pages/available-drugs/Availabledrugs"));
const Availableaccessories = React.lazy(() => import("./components/pages/available-accessories/Availableaccessories"));
const Availabletests = React.lazy(() => import("./components/pages/available-tests/Availabletests"));
const Availableprocedures = React.lazy(() => import("./components/pages/available-procedures/Availableprocedures"));
const AvailableServices = React.lazy(() => import("./components/pages/available-services/AvailableServices"));

//pharmacist
const AddDrugsPharmacist = React.lazy(() => import("./components/pages/available-drugs/AddDrugsPharmacist"));
const AddAccessoriesPharmacist = React.lazy(() => import("./components/pages/available-accessories/AddAccessoriesPharmacist"));
const AvailableaccessoriesPharmacist = React.lazy(() => import("./components/pages/available-accessories/AvailableaccessoriesPharmacist"));
const AvailabledrugsPharmacist = React.lazy(() => import("./components/pages/available-drugs/AvailabledrugsPharmacist"));

//pathologist
const AvailabletestsPathologist = React.lazy(() => import("./components/pages/available-tests/AvailabletestsPathologist"));

// form
const Formlayouts = React.lazy(() => import("./components/pages/form/Formlayouts"));
const Formvalidation = React.lazy(() => import("./components/pages/form/Formvalidation"));
const Formwizard = React.lazy(() => import("./components/pages/form/Formwizard"));
const Fromelements = React.lazy(() => import("./components/pages/form/Fromelements"));

// human resource
const Addemployee = React.lazy(() => import("./components/pages/human-resource/Addemployee"));
const Addnurse = React.lazy(() => import("./components/pages/human-resource/Addnurse"));
const Addpharmacist = React.lazy(() => import("./components/pages/human-resource/Addpharmacist"));
const Addrepresentative = React.lazy(() => import("./components/pages/human-resource/Addrepresentative"));
const Employeelist = React.lazy(() => import("./components/pages/human-resource/Employeelist"));
const Nurselist = React.lazy(() => import("./components/pages/human-resource/Nurselist"));
const Pharmacistlist = React.lazy(() => import("./components/pages/human-resource/Pharmacistlist"));
const Representativelist = React.lazy(() => import("./components/pages/human-resource/Representativelist"));

const Adminhomepage = React.lazy(() => import("./components/pages/Adminhomepage"));
const Receptionisthomepage = React.lazy(() => import("./components/pages/human-resource/Receptionisthomepage"));
const Pharmacisthomepage = React.lazy(() => import("./components/pages/human-resource/Pharmacisthomepage"));
const Pathologisthomepage = React.lazy(() => import("./components/pages/human-resource/Pathologisthomepage"));

// Ledger
const AddLedger = React.lazy(() => import("./components/pages/ledger/AddLedger"));
const AccountsPayable = React.lazy(() => import("./components/pages/ledger/AccountsPayable"));
const AccountsReceivable = React.lazy(() => import("./components/pages/ledger/AccountsReceivable"));
const MonthlyLedgerItemList = React.lazy(() => import("./components/pages/ledger/MonthlyLedgerItemList"));

const AddLedgerReceptionist = React.lazy(() => import("./components/pages/ledger/AddLedgerReceptionist"));
const AccountsPayableReceptionist = React.lazy(() => import("./components/pages/ledger/AccountsPayableReceptionist"));
const AccountsReceivableReceptionist = React.lazy(() => import("./components/pages/ledger/AccountsReceivableReceptionist"));
const MonthlyLedgerItemListReceptionist = React.lazy(() => import("./components/pages/ledger/MonthlyLedgerItemListReceptionist"));

// icons
const Flaticons = React.lazy(() => import("./components/pages/icons/Flaticons"));
const Fontawesome = React.lazy(() => import("./components/pages/icons/Fontawesome"));
const Materialize = React.lazy(() => import("./components/pages/icons/Materialize"));
// maps
const Googlemaps = React.lazy(() => import("./components/pages/maps/Googlemaps"));
const Vectormaps = React.lazy(() => import("./components/pages/maps/Vectormaps"));
// notice
const Addnotice = React.lazy(() => import("./components/pages/notice/Addnotice"));
const Noticelist = React.lazy(() => import("./components/pages/notice/Noticelist"));

const NoticelistReceptionist = React.lazy(() => import(("./components/pages/notice/NoticelistReceptionist")));

// patient
const Addpatient = React.lazy(() => import("./components/pages/patient/Addpatient"));
const Patientlist = React.lazy(() => import("./components/pages/patient/Patientlist"));

// patient case report
const PatientCaseReport = React.lazy(() => import("./components/pages/patient-case-report/PaitentCaseReport"));
const PatientCaseReportReceptionist = React.lazy(() => import("./components/pages/patient-case-report/PatientCaseReportReceptionist"));

//Patient Admit Request List
const PatientAdmitRequestList = React.lazy(() => import("./components/pages/patient/PatientAdmitRequestList"));
const PatientAdmitRequestListReceptionist = React.lazy(() => import("./components/pages/patient/PatientAdmitRequestListReceptionist"));


//Admitted Patient
const Admitpatient = React.lazy(() => import("./components/pages/patient/Admitpatient"));
const Admittedpatientlist = React.lazy(() => import("./components/pages/patient/Admittedpatientlist"));
const Admittedpatientdetails = React.lazy(() => import("./components/pages/patient/Admittedpatientdetails"));
const Editadmittedpatientdetails = React.lazy(() => import("./components/pages/patient/Editadmittedpatientdetails"));
const Deleteadmittedpatientdetails = React.lazy(() => import("./components/pages/patient/Deletepatientadmitdetails"));
const Releasepatient = React.lazy(() => import("./components/pages/patient/Releasepatient"));

const AddAdmittedPatientFollowup = React.lazy(() => import("./components/pages/admitted-patient-followup/AddAdmittedPatientFollowup"));

//PatientProcedure information page for Admin
const AddPatientProcedure = React.lazy(() => import("./components/pages/admitted-patient-procedure/AddPatientProcedure"));
const EditPatientProcedure = React.lazy(() => import("./components/pages/admitted-patient-procedure/EditPatientProcedure"));
const DeletePatientProcedure = React.lazy(() => import("./components/pages/admitted-patient-procedure/DeletePatientProcedure"));

//PatientProcedure information page for receptionist
const AddPatientProcedureReceptionist = React.lazy(() => import("./components/pages/admitted-patient-procedure/AddPatientProcedureReceptionist"));
const EditPatientProcedureReceptionist = React.lazy(() => import("./components/pages/admitted-patient-procedure/EditPatientProcedureReceptionist"));
const DeletePatientProcedureReceptionist = React.lazy(() => import("./components/pages/admitted-patient-procedure/DeletePatientProcedureReceptionist"));

// patient pages for receptionist
const AddpatientReceptionist = React.lazy(() => import("./components/pages/patient/AddpatientReceptionist"));
const PatientSearchReceptionist = React.lazy(() => import("./components/pages/patient/PatientSearchReceptionist"));

//Admitted patient receptionist
const AdmitpatientReceptionist = React.lazy(() => import("./components/pages/patient/AdmitpatientReceptionist"));
const AdmittedpatientlistReceptionist = React.lazy(() => import("./components/pages/patient/AdmittedpatientlistReceptionist"));
const AdmittedpatientdetailsReceptionist = React.lazy(() => import("./components/pages/patient/AdmittedpatientdetailsReceptionist"));
const EditadmittedpatientdetailsReceptionist = React.lazy(() => import("./components/pages/patient/EditadmittedpatientdetailsReceptionist"));
const DeleteadmittedpatientdetailsReceptionist = React.lazy(() => import("./components/pages/patient/DeletepatientadmitdetailsReceptionist"));
const ReleasepatientReceptionist = React.lazy(() => import("./components/pages/patient/ReleasepatientReceptionist"));

//Patient list for pharmacist/pathologist
const AdmittedPatientListPharmacist = React.lazy(() => import("./components/pages/patient/AdmittedpatientlistPharmacist"));
const PatientlistPharmacist = React.lazy(() => import("./components/pages/patient/PatientlistPharmacist"));
const AdmittedPatientListPathologist = React.lazy(() => import("./components/pages/patient/AdmittedPatientListPathologist"));
const PatientlistPathologist = React.lazy(() => import("./components/pages/patient/PatientlistPathologist"));

// patient list page for doctors
const PatientlistDoctor = React.lazy(() => import("./components/pages/patient/PatientlistDoctor"));
const AdmittedpatientlistDoctor = React.lazy(() => import("./components/pages/patient/AdmittedpatientlistDoctor"));
const AdmittedpatientdetailsDoctor = React.lazy(() => import("./components/pages/patient/AdmittedpatientdetailsDoctor"));
const AddAdmittedPatientFollowupDoctor = React.lazy(() => import("./components/pages/admitted-patient-followup/AddAdmittedPatientFollowupDoctor"));

// payment
const Addpayment = React.lazy(() => import("./components/pages/payment/Addpayment"));
const Paymentinvoice = React.lazy(() => import("./components/pages/payment/Paymentinvoice"));
const Paymentlist = React.lazy(() => import("./components/pages/payment/Paymentlist"));
const PatientServiceInvoice = React.lazy(() => import("./components/pages/payment/PatientServiceInvoice"));

const PaymentlistReceptionist = React.lazy(() => import("./components/pages/payment/PaymentlistReceptionist"));
const PatientDataRetrieve = React.lazy(() => import("./components/pages/payment/PatientDataRetrieve"));
const PatientInvoiceUpdate = React.lazy(() => import("./components/pages/payment/PatientInvoiceUpdate"));
const PatientServiceInvoiceReceptionist = React.lazy(() => import("./components/pages/payment/PatientServiceInvoiceReceptionist"));

//Payment invoice for pharmacist/pathologist
const PaymentinvoicePharmacist = React.lazy(() => import("./components/pages/payment/PaymentinvoicePharmacist"));
const PaymentinvoicePathologist = React.lazy(() => import("./components/pages/payment/PaymentinvoicePathologist"));
const PathologyinvoiceReceptionist = React.lazy(() => import("./components/pages/payment/PathologyinvoiceReceptionist"));

const PaymentDetails = React.lazy(() => import("./components/pages/payment/PaymentDetails"));
const PaymentDetailsReceptionist = React.lazy(() => import("./components/pages/payment/PaymentdetailsReceptionist"));

// Admitted Page Service Bill generate page
const AdmittedPatientServiceBill = React.lazy(() => import("./components/pages/admitted-patient-bill/AdmittedPatientServiceBill"));
const AdmittedPatientServiceBillReceptionist = React.lazy(() => import("./components/pages/admitted-patient-bill/AdmittedPatientServiceBillReceptionist"));
// popups
const Sweetalerts = React.lazy(() => import("./components/pages/popups/Sweetalerts"));
const Toast = React.lazy(() => import("./components/pages/popups/Toast"));
// prebuilt pages
const Comingsoon = React.lazy(() => import("./components/pages/prebuilt-pages/Comingsoon"));
const Defaultlogin = React.lazy(() => import("./components/pages/prebuilt-pages/Defaultlogin"));
const Defaultregister = React.lazy(() => import("./components/pages/prebuilt-pages/Defaultregister"));
const Error = React.lazy(() => import("./components/pages/prebuilt-pages/Error"));
const Faq = React.lazy(() => import("./components/pages/prebuilt-pages/Faq"));
const Invoice = React.lazy(() => import("./components/pages/prebuilt-pages/Invoice"));
const Lockscreen = React.lazy(() => import("./components/pages/prebuilt-pages/Lockscreen"));
const Modallogin = React.lazy(() => import("./components/pages/prebuilt-pages/Modallogin"));
const Modalregister = React.lazy(() => import("./components/pages/prebuilt-pages/Modalregister"));
const Portfolio = React.lazy(() => import("./components/pages/prebuilt-pages/Portfolio"));
const Userprofile = React.lazy(() => import("./components/pages/prebuilt-pages/Userprofile"));
//Receptionist user profile
const UserprofileReceptionist = React.lazy(() => import(("./components/pages/prebuilt-pages/UserprofileReceptionist")));
// reports
const Doctorreport = React.lazy(() => import("./components/pages/reports/Doctorreport"));
const Patientreport = React.lazy(() => import("./components/pages/reports/Patientreport"));
const Totalreport = React.lazy(() => import("./components/pages/reports/Totalreport"));
// Report pages for receptionist
const PatientreportReceptionist = React.lazy(() => import("./components/pages/reports/PatientreportReceptionist"));
const PatientReportPathologist = React.lazy(() => import("./components/pages/reports/PatientReportPathologist"));

const TotalreportReceptionist = React.lazy(() => import("./components/pages/reports/TotalreportReceptionist"));
const DoctorreportReceptionist = React.lazy(() => import("./components/pages/reports/DoctorreportReceptionist"));
// tables
const Basictables = React.lazy(() => import("./components/pages/tables/Basictables"));
const Datatables = React.lazy(() => import("./components/pages/tables/Datatables"));
// uiadvance
const Cropper = React.lazy(() => import("./components/pages/uiadvance/Cropper"));
const Draggable = React.lazy(() => import("./components/pages/uiadvance/Draggable"));
const Modals = React.lazy(() => import("./components/pages/uiadvance/Modals"));
const Rangeslider = React.lazy(() => import("./components/pages/uiadvance/Rangeslider"));
const Sliders = React.lazy(() => import("./components/pages/uiadvance/Sliders"));
const Tour = React.lazy(() => import("./components/pages/uiadvance/Tour"));
const Ratings = React.lazy(() => import("./components/pages/uiadvance/Ratings"));
// uibasic
const Accordions = React.lazy(() => import("./components/pages/uibasic/Accordions"));
const Alerts = React.lazy(() => import("./components/pages/uibasic/Alerts"));
const Badges = React.lazy(() => import("./components/pages/uibasic/Badges"));
const Breadcrumbs = React.lazy(() => import("./components/pages/uibasic/Breadcrumbs"));
const Buttons = React.lazy(() => import("./components/pages/uibasic/Buttons"));
const Cards = React.lazy(() => import("./components/pages/uibasic/Cards"));
const Pagination = React.lazy(() => import("./components/pages/uibasic/Pagination"));
const Preloaders = React.lazy(() => import("./components/pages/uibasic/Preloader"));
const Progressbars = React.lazy(() => import("./components/pages/uibasic/Progressbars"));
const Tabs = React.lazy(() => import("./components/pages/uibasic/Tabs"));
const Typography = React.lazy(() => import("./components/pages/uibasic/Typography"));

const PrescriptionPrint = React.lazy(() => import("./components/pages/pages-for-printing/PrescriptionPrint"));
const AppointmentPrint = React.lazy(() => import("./components/pages/pages-for-printing/AppointmentPrint"));

const PrescriptionPrintReceptionist = React.lazy(() => import("./components/pages/pages-for-printing/PrescriptionPrintReceptionist"));
const PrescriptionPrintPharmacist = React.lazy(() => import("./components/pages/pages-for-printing/PrescriptionPrintPharmacist"));
const AppointmentPrintReceptionist = React.lazy(() => import("./components/pages/pages-for-printing/AppointmentPrintReceptionist"));

const PrescriptionPrintDoctor = React.lazy(() => import("./components/pages/pages-for-printing/PrescriptionPrintDoctor"));

const PharmacyDirectSell = React.lazy(() => import("./components/pages/direct-sell/PharmacyDirectSell"))
const PharmacyDirectSellPharmacist = React.lazy(() => import("./components/pages/direct-sell/PharmacyDirectSellPharmacist"))

const PathologyDirectSell = React.lazy(() => import("./components/pages/direct-sell/PathologyDirectSell"))
const PathologyDirectSellReceptionist = React.lazy(() => import("./components/pages/direct-sell/PathologyDirectSellReceptionist"))

function App() {
  return (
    <div>
      <div> <Toaster /> </div>
      <Router basename={'/'}>
        <Suspense fallback={<div></div>}>
          <Preloader />
          <Switch>
            {/* <AllProtectedRoutes /> */}
            <Route exact path="/" component={Defaultlogin} />
            <PrivateRoute path="/animation" component={Animation} />
            <PrivateRoute path="/widgets" component={Widgets} />

            {/* appointment */}
            <PrivateRoute path="/admin/appointment/add" component={Addappointment} />
            <PrivateRoute path="/admin/appointment/list" component={Appointmentlist} />

            {/* appointment with search and add patient profile*/}
            <PrivateRoute path="/admin/patient/appointment/add" component={AddSearchPatientAppointment} />
            <PrivateRoute path="/receptionist/patient/appointment/add" component={AddSearchPatientAppointmentReceptionist} />

            {/* appointment page for receptionist */}
            <PrivateRoute path="/receptionist/appointment/add" component={AddappointmentReceptionist} />
            <PrivateRoute path="/receptionist/appointment/list" component={AppointmentlistReceptionist} />


            {/* appointment list for doctor */}
            <PrivateRoute path="/doctor/my-appointments" component={AppointmentlistDoctor} />

            {/* appointment list for patient */}
            <PrivateRoute path="/patient/my-appointments" component={AppointmentlistPatient} />

            {/* appointment Vital Signs*/}
            <PrivateRoute path="/nurse/appointment/vital/signs/add" component={AddAppointmentVitalSigns} />
            <PrivateRoute path="/admin/appointment/vital/signs/edit" component={EditAppointmentVitalSigns} />

            <PrivateRoute path="/doctor/appointment/vital/signs/edit" component={EditAppointmentVitalSignsDoctor} />

            {/* apps */}
            <PrivateRoute path="/apps/chat" component={Chat} />
            <PrivateRoute path="/apps/email" component={Email} />
            <PrivateRoute path="/apps/to-do-list" component={Todolist} />

            {/* Room manager */}
            <PrivateRoute path="/room/category/add" component={AddRoomCategory} />
            <PrivateRoute path="/room/category/list" component={RoomCategoryList} />
            <PrivateRoute path="/room/add" component={AddRoom} />
            <PrivateRoute path="/room/list" component={RoomList} />

            {/* bed manager */}
            <PrivateRoute path="/bed/category/add" component={Addbedcategory} />
            <PrivateRoute path="/bed/category/list" component={Bedcategorylist} />
            <PrivateRoute path="/bed/add" component={Addbed} />
            <PrivateRoute path="/bed/list" component={Bedlist} />

            <PrivateRoute path="/receptionist/bed/list" component={BedlistReceptionist} />

            {/* charts */}
            <PrivateRoute path="/charts/chartjs" component={Chartjs} />
            <PrivateRoute path="/charts/google-charts" component={Googlecharts} />

            {/* dashboard */}
            <PrivateRoute path="/dashboard/client-management" component={Clientmanagement} />
            <PrivateRoute path="/dashboard/project-management" component={Projectmanagement} />
            <PrivateRoute path="/dashboard/social-media" component={Socialmedia} />
            <PrivateRoute path="/dashboard/web-analytics" component={Webanalytics} />

            {/* department */}
            <PrivateRoute path="/department/add-department" component={Adddepartment} />
            <PrivateRoute path="/department/department-list" component={Departmentlist} />

            {/* Department list page for receptionist */}
            <PrivateRoute path="/receptionist/department/department-list" component={DepartmentlistReceptionist} />

            {/* patient */}
            <PrivateRoute path="/patient/homepage" component={PatientHomepage} />

            {/* doctor */}
            <PrivateRoute path="/doctor/add" component={Adddoctor} />
            <PrivateRoute path="/doctor/list" component={Doctorlist} />
            <PrivateRoute path="/doctor/homepage" component={DoctorHomepage} />

            {/* doctorlist page for receptionist */}
            <PrivateRoute path="/receptionist/doctor/list" component={DoctorlistReceptionist} />


            {/* doctor schedule */}
            <PrivateRoute path="/doctor-schedule/add-schedule" component={Addschedule} />
            <PrivateRoute path="/doctor-schedule/schedule-list" component={Schedulelist} />

            {/* doctor schedule page for receptionist */}
            <PrivateRoute path="/receptionist/doctor-schedule/schedule-list" component={SchedulelistReceptionist} />

            {/* Consultation */}
            <PrivateRoute path="/admin/consultation/add" component={FullConsultation} />
            <PrivateRoute path="/admin/consultation/list" component={Consultationlist} />
            <PrivateRoute path="/admin/consultation/details" component={Consultationdetails} />
            <PrivateRoute path="/admin/consultation/edit" component={Editconsultation} />
            <PrivateRoute path="/admin/consultation/delete" component={Deleteconsultation} />


            <PrivateRoute path={"/doctor/consultation/add"} component={FullConsultationDoctor} />
            <PrivateRoute path={"/doctor/consultation/today"} component={ConsultationlistDoctor} />
            <PrivateRoute path={"/doctor/patients"} component={PatientlistDoctor} />
            <PrivateRoute path={"/doctor/consultation/details"} component={ConsultationdetailsDoctor} />
            <PrivateRoute path={"/doctor/consultation/edit"} component={EditconsultationDoctor} />
            <PrivateRoute path={"/doctor/consultation/delete"} component={DeleteconsultationDoctor} />

            <PrivateRoute path="/doctor/consultation/list/patients" component={PatientconsultationList} />

            <PrivateRoute path="/patient/my-consultations" component={ConsultationlistPatient} />
            <PrivateRoute path="/patient/consultation-details" component={ConsultationdetailsPatient} />

            <PrivateRoute path="/patient/pcc/form" component={PatientCaseReport} />
            <PrivateRoute path="/receptionist/patient/pcc/form" component={PatientCaseReportReceptionist} />

            {/* Prescription*/}

            {/* for Admin */}
            <PrivateRoute path="/admin/prescription/add" component={Addprescription} />
            <PrivateRoute path="/prescription/list" component={Prescriptionlist} />
            <PrivateRoute path="/prescription/details" component={Prescriptiondetails} />
            <PrivateRoute path="/admin/prescription/edit" component={Editprescription} />
            <PrivateRoute path="/admin/prescription/delete" component={Deleteprescription} />

            {/* for Doctor */}
            <PrivateRoute path="/doctor/prescription/add" component={AddprescriptionDoctor} />
            <PrivateRoute path="/doctor/prescription/details" component={PrescriptiondetailsDoctor} />
            <PrivateRoute path="/doctor/prescription/edit" component={EditprescriptionDoctor} />
            <PrivateRoute path="/doctor/prescription/delete" component={DeleteprescriptionDoctor} />

            {/* for Pharmacist */}
            <PrivateRoute path="/pharmacist/admitted/patient/prescription/list" component={AdmittedPatientPrescriptionListPharmacist} />
            <PrivateRoute path="/pharmacist/prescription/details" component={PrescriptiondetailsPharmacist} />
            <PrivateRoute path="/pharmacist/prescription/list" component={PrescriptionlistPharmacist} />

            {/* for Receptionist */}
            <PrivateRoute path="/receptionist/prescription/list" component={PrescriptionListReceptionist} />
            <PrivateRoute path="/receptionist/prescription/full" component={FullPrescriptionReceptionist} />
            <PrivateRoute path="/receptionist/prescription/details" component={PrescriptionDetailsReceptionist} />
            <PrivateRoute path="/receptionist/prescription/test/details" component={PrescriptionTestDetailsReceptionist} />

            {/* for Patient */}
            <PrivateRoute path="/patient/prescription-list" component={PrescriptionlistPatient} />
            <PrivateRoute path="/patient/prescription-details" component={PrescriptiondetailsPatient} />

            {/* for Pathologist */}
            <PrivateRoute path="/pathologist/admitted/patient/prescription/list" component={AdmittedPatientPrescriptionListPathologist} />
            <PrivateRoute path="/pathologist/prescription/list" component={PrescriptionlistPathologist} />
            <PrivateRoute path="/pathologist/prescription/test/details" component={PrescriptiontestdetailsPathologist} />

            {/* Prescription test*/}

            {/* for Admin */}
            <PrivateRoute path="/admin/prescription/test/add" component={Addtest} />
            <PrivateRoute path="/admin/prescription/test/details" component={Prescriptiontestdetails} />
            <PrivateRoute path="/admin/prescription/test/edit" component={Edittest} />
            <PrivateRoute path="/admin/prescription/test/delete" component={Deletetest} />

            {/* for Doctor */}
            <PrivateRoute path="/doctor/prescription/test/add" component={AddtestDoctor} />
            <PrivateRoute path="/doctor/prescription/test/details" component={PrescriptiontestdetailsDoctor} />
            <PrivateRoute path="/doctor/prescription/test/edit" component={EdittestDoctor} />
            <PrivateRoute path="/doctor/prescription/test/delete" component={DeletetestDoctor} />

            {/* for Patient */}
            <PrivateRoute path="/patient/consultation/prescription-test-details" component={PrescriptiontestdetailsPatient} />

            {/* for Receptionist */}
            <PrivateRoute path="/receptionist/prescription/test/details" component={PrescriptionTestDetailsReceptionist} />
            <PrivateRoute path="/receptionist/prescription/test/edit" component={EditTestReceptionist} />
            <PrivateRoute path="/receptionist/prescription/test/invoice/generate" component={PrescriptionTestInvoiceGenerateReceptionist} />


            {/* for Pathologist */}
            <PrivateRoute path="/pathologist/prescription/test/edit" component={EditTestPathologist} />
            <PrivateRoute path="/pathologist/prescription/test/list" component={PrescriptionTestListPathologist} />

            {/* <PrescriptionTestListPathologist path="/pathologist/prescription/test/list" />
            <PrivateRoute path="/pathologist/prescription/test/edit" component={EditTestPathologist} />
            <PrivateRoute path="/pathologist/prescription/test/list" component={PrescriptionTestListPathologist} />

            {/* Follow up*/}
            <PrivateRoute path="/admin/consultation/followup/list" component={Followuplist} />
            <PrivateRoute path="/admin/consultation/followup/add" component={FullFollowup} />

            <PrivateRoute path="/doctor/consultation/followup/add" component={FullFollowupDoctor} />
            <PrivateRoute path="/doctor/consultation/followup/list" component={FollowuplistDoctor} />

            {/* Available Drugs, Accessories and Tests of Pharmacy, Pathology and Admin*/}

            {/* For Admin */}
            <PrivateRoute path="/pharmacy/drugs/add" component={AddDrugs} />
            <PrivateRoute path="/pharmacy/drugs/list" component={Availabledrugs} />

            <PrivateRoute path="/pharmacy/accessories/add" component={AddAccessories} />
            <PrivateRoute path="/pharmacy/accessories/list" component={Availableaccessories} />

            <PrivateRoute path="/pathology/procedures/add" component={AddProcedures} />
            <PrivateRoute path="/pathology/procedures/list" component={Availableprocedures} />

            <PrivateRoute path="/pathology/tests/add" component={AddTests} />
            <PrivateRoute path="/pathology/tests/list" component={Availabletests} />

            <PrivateRoute path="/pathology/services/add" component={AddServices} />
            <PrivateRoute path="/pathology/services/list" component={AvailableServices} />

            {/* For Pharmacist*/}
            <PrivateRoute path="/pharmacist/pharmacy/drugs/add" component={AddDrugsPharmacist} />
            <PrivateRoute path="/pharmacist/pharmacy/accessories/add" component={AddAccessoriesPharmacist} />
            <PrivateRoute path="/pharmacist/pharmacy/drugs/list" component={AvailabledrugsPharmacist} />
            <PrivateRoute path="/pharmacist/pharmacy/accessories/list" component={AvailableaccessoriesPharmacist} />

            {/* <PrivateRoute path="/pathologist/tests/list" component={AvailabletestsPathologist} /> */}
            <PrivateRoute path="/pathologist/tests/list" component={AvailabletestsPathologist} />

            {/* form */}
            <PrivateRoute path="/form/form-layout" component={Formlayouts} />
            <PrivateRoute path="/form/form-validation" component={Formvalidation} />
            <PrivateRoute path="/form/form-wizard" component={Formwizard} />
            <PrivateRoute path="/form/form-elements" component={Fromelements} />
            {/* human resource */}
            <PrivateRoute path="/staff/add" component={Addemployee} />
            <PrivateRoute path="/human-resource/add-nurse" component={Addnurse} />
            <PrivateRoute path="/human-resource/add-pharmacist" component={Addpharmacist} />
            <PrivateRoute path="/human-resource/add-representative" component={Addrepresentative} />
            <PrivateRoute path="/staff/list" component={Employeelist} />
            <PrivateRoute path="/human-resource/nurse-list" component={Nurselist} />
            <PrivateRoute path="/human-resource/pharmacist-list" component={Pharmacistlist} />
            <PrivateRoute path="/human-resource/representative-list" component={Representativelist} />

            <PrivateRoute path={"/admin/homepage"} component={Adminhomepage} />
            <PrivateRoute path="/receptionist/homepage" component={Receptionisthomepage} />
            <PrivateRoute path="/pharmacist/homepage" component={Pharmacisthomepage} />
            {/* <PrivateRoute path="/pathologist/homepage" component={Pathologisthomepage} /> */}
            <PrivateRoute path="/pathologist/homepage" component={Pathologisthomepage} />
            <PrivateRoute path="/nurse/homepage" component={NurseHomepage} />

            {/* newly added */}
            {/* <Route
          path='/human-resource/admin-homepage'
          render={()=>(
            <PrivateRoute redirectTo ='/' > 
            <Adminhomepage />
            </PrivateRoute>
          )} />

          <Route
          path='/human-resource/receptionist-homepage'
          render={()=>(
            <PrivateRoute redirectTo ='/' > 
            <Receptionisthomepage />
            </PrivateRoute>
          )} />       

          <Route
          path='/human-resource/pharmacist-homepage'
          render={()=>(
            <PrivateRoute redirectTo ='/' > 
            <Pharmacisthomepage />
            </PrivateRoute>
          )} /> */}



            {/* icons */}
            <PrivateRoute path="/icons/flaticons" component={Flaticons} />
            <PrivateRoute path="/icons/fontawesome" component={Fontawesome} />
            <PrivateRoute path="/icons/materialize" component={Materialize} />
            {/* maps */}
            <PrivateRoute path="/maps/google-maps" component={Googlemaps} />
            <PrivateRoute path="/maps/vector-maps" component={Vectormaps} />
            {/* notice */}
            <PrivateRoute path="/notice/add-notice" component={Addnotice} />
            <PrivateRoute path="/notice/notice-list" component={Noticelist} />
            <PrivateRoute path="/receptionist/notice/notice-list" component={NoticelistReceptionist} />

            {/* patient */}

            {/* for Admin */}
            <PrivateRoute path="/admin/patient/add" component={Addpatient} />
            <PrivateRoute path="/admin/patient/list" component={Patientlist} />
            <PrivateRoute path="/admin/patient/admit/request/list" component={PatientAdmitRequestList} />

            <PrivateRoute path="/admin/patient/admit" component={Admitpatient} />
            <PrivateRoute path="/admin/patient/admitted/list" component={Admittedpatientlist} />
            <PrivateRoute path="/admin/admitted/patient/details" component={Admittedpatientdetails} />
            <PrivateRoute path="/admin/admitted/patient/edit" component={Editadmittedpatientdetails} />
            <PrivateRoute path="/admin/admitted/patient/delete" component={Deleteadmittedpatientdetails} />
            <PrivateRoute path="/admin/admitted/patient/release" component={Releasepatient} />

            <PrivateRoute path="/admin/admitted/patient/followup/add" component={AddAdmittedPatientFollowup} />

            <PrivateRoute path="/admin/admitted/patient/procedure/add" component={AddPatientProcedure} />
            <PrivateRoute path="/admin/admitted/patient/procedure/edit" component={EditPatientProcedure} />
            <PrivateRoute path="/admin/admitted/patient/procedure/delete" component={DeletePatientProcedure} />

            {/* for Doctor */}
            <PrivateRoute path="/doctor/patient/admitted/list" component={AdmittedpatientlistDoctor} />
            <PrivateRoute path="/doctor/admitted/patient/details" component={AdmittedpatientdetailsDoctor} />
            <PrivateRoute path="/doctor/admitted/patient/followup/add" component={AddAdmittedPatientFollowupDoctor} />

            {/* for Receptionist */}
            <PrivateRoute path="/receptionist/patient/add" component={AddpatientReceptionist} />
            {/* <PrivateRoute path="/receptionist/patient/list" component={PatientlistReceptionist} /> */}
            <PrivateRoute path="/receptionist/patient/search" component={PatientSearchReceptionist} />
            <PrivateRoute path="/receptionist/patient/admit/request/list" component={PatientAdmitRequestListReceptionist} />

            <PrivateRoute path="/receptionist/patient/admit" component={AdmitpatientReceptionist} />
            <PrivateRoute path="/receptionist/patient/admitted/list" component={AdmittedpatientlistReceptionist} />
            <PrivateRoute path="/receptionist/admitted/patient/details" component={AdmittedpatientdetailsReceptionist} />
            <PrivateRoute path="/receptionist/admitted/patient/edit" component={EditadmittedpatientdetailsReceptionist} />
            <PrivateRoute path="/receptionist/admitted/patient/delete" component={DeleteadmittedpatientdetailsReceptionist} />
            <PrivateRoute path="/receptionist/admitted/patient/release" component={ReleasepatientReceptionist} />

            <PrivateRoute path="/receptionist/patient/admit/request/list" component={PatientAdmitRequestListReceptionist} />
            <PrivateRoute path="/receptionist/admitted/patient/procedure/add" component={AddPatientProcedureReceptionist} />
            <PrivateRoute path="/receptionist/admitted/patient/procedure/edit" component={EditPatientProcedureReceptionist} />
            <PrivateRoute path="/receptionist/admitted/patient/procedure/delete" component={DeletePatientProcedureReceptionist} />

            {/* for Pharmacist & Pathologist */}
            <PrivateRoute path="/pharmacist/patient/admitted/list" component={AdmittedPatientListPharmacist} />
            <PrivateRoute path="/pharmacist/patient/list" component={PatientlistPharmacist} />
            {/* <PrivateRoute path="/pathologist/patient/admitted/list" component={AdmittedPatientListPathologist} />
            <PrivateRoute path="/pathologist/patient/list" component={PatientlistPathologist} /> */}
            <PrivateRoute path="pathologist/patient/admitted/list" component={AdmittedPatientListPathologist} />
            <PrivateRoute path="pathologist/patient/list" component={PatientlistPathologist} />

            {/* payment */}
            <PrivateRoute path="/payment/add-payment" component={Addpayment} />
            <PrivateRoute path="/payment/payment-invoice" component={Paymentinvoice} />

            {/* For Admin */}
            <PrivateRoute path="/payment/pending/list" component={Paymentlist} />
            <PrivateRoute path="/payment/service/invoice" component={PatientServiceInvoice} />
            <PrivateRoute path={"/payment/details"} component={PaymentDetails} />
            <PrivateRoute path={"/payment/service"} component={AdmittedPatientServiceBill} />

            {/* For Receptionist */}
            <PrivateRoute path="/receptionist/payment/pending/list" component={PaymentlistReceptionist} />
            <PrivateRoute path="/receptionist/payment/patient/invoice/data" component={PatientDataRetrieve} />
            <PrivateRoute path="/receptionist/payment/patient/invoice/update" component={PatientInvoiceUpdate} />
            <PrivateRoute path="/receptionist/payment/service/invoice" component={PatientServiceInvoiceReceptionist} />
            <PrivateRoute path="/receptionist/payment/pathology/invoice" component={PathologyinvoiceReceptionist} />
            <PrivateRoute path="/receptionist/payment/details" component={PaymentDetailsReceptionist} />
            <PrivateRoute path={"/receptionist/payment/service"} component={AdmittedPatientServiceBillReceptionist} />

            {/* For Pharmacist & Pathologist */}
            <PrivateRoute path="/pharmacist/payment/invoice" component={PaymentinvoicePharmacist} />
            <PrivateRoute path="/pathologist/payment/invoice" component={PaymentinvoicePathologist} />


            {/* popups */}
            <PrivateRoute path="/popups/sweet-alerts" component={Sweetalerts} />
            <PrivateRoute path="/popups/toast" component={Toast} />
            {/* prebuilt pages */}
            <PrivateRoute path="/prebuilt-pages/coming-soon" component={Comingsoon} />
            {/* <PrivateRoute path="/prebuilt-pages/default-login" component={Defaultlogin} /> */}
            <PrivateRoute path="/prebuilt-pages/default-register" component={Defaultregister} />
            <PrivateRoute path="/prebuilt-pages/error" component={Error} />
            <PrivateRoute path="/prebuilt-pages/faq" component={Faq} />
            <PrivateRoute path="/prebuilt-pages/invoice" component={Invoice} />
            <PrivateRoute path="/prebuilt-pages/lock-screen" component={Lockscreen} />
            <PrivateRoute path="/prebuilt-pages/modal-login" component={Modallogin} />
            <PrivateRoute path="/prebuilt-pages/modal-register" component={Modalregister} />
            <PrivateRoute path="/prebuilt-pages/portfolio" component={Portfolio} />
            <PrivateRoute path="/prebuilt-pages/user-profile" component={Userprofile} />

            {/* Receptionist User Profile */}
            <PrivateRoute path="/receptionist-profile" component={UserprofileReceptionist} />

            {/* reports */}
            <PrivateRoute path="/report/doctor-report" component={Doctorreport} />
            <PrivateRoute path="/report/patient-report" component={Patientreport} />
            <PrivateRoute path="/report/total-report" component={Totalreport} />

            {/* ledger */}
            <PrivateRoute path="/admin/ledger/add" component={AddLedger} />
            <PrivateRoute path="/admin/ledger/accounts/payable" component={AccountsPayable} />
            <PrivateRoute path="/admin/ledger/accounts/receivable" component={AccountsReceivable} />
            <PrivateRoute path="/admin/ledger/monthly" component={MonthlyLedgerItemList} />

            <PrivateRoute path="/receptionist/ledger/add" component={AddLedgerReceptionist} />
            <PrivateRoute path="/receptionist/ledger/accounts/payable" component={AccountsPayableReceptionist} />
            <PrivateRoute path="/receptionist/ledger/accounts/receivable" component={AccountsReceivableReceptionist} />
            <PrivateRoute path="/receptionist/ledger/monthly" component={MonthlyLedgerItemListReceptionist} />

            <PrivateRoute path="/admin/prescription/test/report" component={Patientreport} />
            <PrivateRoute path="/receptionist/prescription/test/report" component={PatientreportReceptionist} />
            <PrivateRoute path="/pathologist/prescription/test/report" component={PatientReportPathologist} />

            <PrivateRoute path="/receptionist/report/doctor-report" component={DoctorreportReceptionist} />
            <PrivateRoute path="/receptionist/report/total-report" component={TotalreportReceptionist} />
            {/* tables */}
            <PrivateRoute path="/tables/basic-tables" component={Basictables} />
            <PrivateRoute path="/tables/data-tables" component={Datatables} />
            {/* uiadvance */}
            <PrivateRoute path="/ui-advanced/cropper" component={Cropper} />
            <PrivateRoute path="/ui-advanced/draggables" component={Draggable} />
            <PrivateRoute path="/ui-advanced/modals" component={Modals} />
            <PrivateRoute path="/ui-advanced/range-slider" component={Rangeslider} />
            <PrivateRoute path="/ui-advanced/sliders" component={Sliders} />
            <PrivateRoute path="/ui-advanced/tour" component={Tour} />
            <PrivateRoute path="/ui-advanced/rating" component={Ratings} />
            {/* uibasic */}
            <PrivateRoute path="/ui-basic/accordions" component={Accordions} />
            <PrivateRoute path="/ui-basic/alerts" component={Alerts} />
            <PrivateRoute path="/ui-basic/badges" component={Badges} />
            <PrivateRoute path="/ui-basic/breadcrumbs" component={Breadcrumbs} />
            <PrivateRoute path="/ui-basic/buttons" component={Buttons} />
            <PrivateRoute path="/ui-basic/cards" component={Cards} />
            <PrivateRoute path="/ui-basic/pagination" component={Pagination} />
            <PrivateRoute path="/ui-basic/preloader" component={Preloaders} />
            <PrivateRoute path="/ui-basic/progress-bars" component={Progressbars} />
            <PrivateRoute path="/ui-basic/tabs" component={Tabs} />
            <PrivateRoute path="/ui-basic/typography" component={Typography} />

            <PrivateRoute path="/admin/print/appointment" component={AppointmentPrint} />
            <PrivateRoute path="/admin/print/prescription" component={PrescriptionPrint} />

            <PrivateRoute path="/receptionist/print/appointment" component={AppointmentPrintReceptionist} />
            <PrivateRoute path="/receptionist/print/prescription" component={PrescriptionPrintReceptionist} />
            <PrivateRoute path="/pharmacist/print/prescription" component={PrescriptionPrintPharmacist} />
            <PrivateRoute path="/doctor/print/prescription" component={PrescriptionPrintDoctor} />

            <PrivateRoute path="/admin/pharmacy/direct/sell" component={PharmacyDirectSell} />
            <PrivateRoute path="/pharmacist/pharmacy/direct/sell" component={PharmacyDirectSellPharmacist} />

            <PrivateRoute path="/admin/pathology/direct/sell" component={PathologyDirectSell}/>
            <PrivateRoute path="/receptionist/pathology/direct/sell" component={PathologyDirectSellReceptionist}/>
            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </div>

  );
}

export default App;
