import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

import loggedReducer from "./isLoggedIn";
import userDetailsReducer from "./userDetails";
import checkTokenReducer from "./checkTokenReducer";
import { drugReducer, testReducer } from "./drugTestReducer";

const appReducer = combineReducers({
    isLoggedIn: loggedReducer,
    userDetails: userDetailsReducer,
    checkToken: checkTokenReducer,
    drugData: drugReducer,
    testData: testReducer,
});

const indexReducer = (state, action) => {
    if (action.type === "SIGN_OUT") {
        storage.removeItem('persist:root');
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export default indexReducer;