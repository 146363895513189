import React, { useEffect } from "react";
import axios from "axios";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userSignIn, userSignOut } from "./redux/actions";
import toast from "react-hot-toast";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const stateLoggedInValue = useSelector((state) => state.isLoggedIn);
  const checkTokenValue = useSelector((state) => state.checkToken);

  const dispatch = useDispatch();
  
  useEffect(() => {
    let isMounted = true;
    const Tokenverifyrefresh = async () => {
      await axios.post(`/accounts/cookie/token/verify`, {})
        .then((response) => {
          dispatch(userSignIn());
        })
        .catch((error) => {
          axios.post(`/accounts/cookie/token/refresh`, {})
            .then((response) => {
            })
            .catch((error) => {
              dispatch(userSignOut());
              toast.error("Session Timed Out!\n Please login.", { duration: 3000 });
            });
        });
    };

    Tokenverifyrefresh();

    return () => {
      isMounted = false;
    };
  }, [checkTokenValue]);

  return (
    <Route
      {...rest}
      render={props =>
        // replace this with your own authentication logic
        stateLoggedInValue ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
