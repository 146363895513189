export const userSignIn = () => {
    return {
        type: 'SIGN_IN'
    };
};

export const userSignOut = () => {
    return {
        type: 'SIGN_OUT'
    };
};

export const userDetails = (userObject) => {
    return {
        type: "USER_DETAILS",
        payload: userObject
    };
};

export const checkToken = () => {
    return {
        type: "CHECK_TOKEN"
    };
};

export const suggestedDrugsAction = (drugs) => {
    return {
        type: "SUGGESTED_DRUGS",
        payload: drugs
    };
};

export const suggestedTestsAction = (tests) => {
    return {
        type: "SUGGESTED_TESTS",
        payload: tests
    };
};

export const clearDrugTestDataAction = () => {
    return {
        type: "CLEAR_DRUG_TEST_DATA"
    };
};
