import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CheckUserType } from "./BasicUtils";

const NotFound = () => {
  const user = useSelector(state=> state.userDetails.user)
  const userType = CheckUserType(user)
  const returnPage = `/${userType}/homepage`
  return (
    <div
      align="center"
      style={{
        // center all the items
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <h1>404, Page Not Found!</h1>
      <br />
      <Link to={returnPage}>
        <button className="btn btn-outline-info">Home</button>
      </Link>
    </div>
  );
};

export default NotFound;
